<template>
  <main>
    <b-overlay :show="loading">
      <validation-observer ref="form">
        <b-card title="Daftar Penyimpanan Barang">
          <b-row>
            <b-col md="2" sm="4" class="">
              <b-modal v-if="allowCreate()" v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary"
                ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
                <b-form>
                  <b-form-group>
                    <!-- <label for="penyimpanan_id">Penyimpanan</label> -->
                    <b-form-input v-model="id" id="penyimpanan_id" placeholder="Nama Penyimpanan_id" disabled hidden />
                    <!-- hidden -->
                  </b-form-group>
                  <b-form-group>
                    <label for="namaBarang">Nama Barang</label>
                    <b-form-input v-model="namaBarang" id="namaBarang" placeholder="Nama Barang" disabled />
                    <!-- hidden -->
                  </b-form-group>
                  <b-form-group>
                    <label for="tanggal">Tanggal</label>
                    <flat-pickr v-model="tanggal" class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                  </b-form-group>
                  <b-row>
                    <b-col md="6" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="jumlah">Jumlah</label>
                        <b-form-input v-model="jumlah" type="number" id="jumlah" placeholder="Jumlah" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="optkonversi.length > 0">
                      <b-form-group>
                        <label for="konversi">Konversi</label>
                        <b-form-select v-model="id_satuan" :reduce="(option) => option.value" :options="optkonversi"
                          id="konversi" placeholder="konversi" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label for="nilai" v-if="optkonversi.length > 0">Hasil Konversi</label>
                        <label for="nilai" v-if="optkonversi.length == 0">Jumlah</label>
                        <b-form-input v-model="nilai" type="number" id="nilai" placeholder="nilai" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="satuan">Satuan</label>
                        <b-form-input v-model="satuan" id="satuan" placeholder="satuan" disabled />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <label for="keterangan">Keterangan</label>
                    <b-form-textarea v-model="keterangan" id="keterangan" placeholder="Keterangan" />
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                      Tambah
                    </b-button>
                    <b-button size="sm" variant="danger" @click="resetForm">
                      Batal
                    </b-button>
                  </section>
                </template>
              </b-modal>
              <b-modal v-if="allowUpdate()" v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary"
                ok-title="Ubah" cancel-title="Batal" centered title="Form Ubah">
                <b-form>
                  <b-form-group>
                    <label for="penyimpanan_id">Penyimpanan</label>
                    <b-form-input v-model="id" id="penyimpanan_id" placeholder="Nama Penyimpanan_id" disabled hidden />
                  </b-form-group>
                  <b-form-group>
                    <label for="tanggal">Tanggal</label>
                    <!-- <flat-pickr
                      v-model="tanggal"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    /> -->
                    <b-form-datepicker id="example-datepicker" v-model="tanggal" class="form-control" />
                  </b-form-group>
                  <b-form-group>
                    <label for="jumlah">Jumlah</label>
                    <b-form-input v-model="jumlah" type="number" id="jumlah" placeholder="Jumlah" />
                  </b-form-group>
                  <b-form-group>
                    <label for="keterangan">Keterangan</label>
                    <b-form-textarea v-model="keterangan" id="keterangan" placeholder="Keterangan" />
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="info" @click="submit">
                      Ubah
                    </b-button>
                    <b-button size="sm" variant="danger" @click="resetForm">
                      Batal
                    </b-button>
                  </section>
                </template>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getData()" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="
                  filter = '';
                getData();
                ">
                  Clear
                </b-button>
              </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table small bordered striped hover responsive :per-page="perPage" 
                :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" >
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ row.index + 1 }}
                  </strong>
                </template>
                <template #cell(tanggal)="{ item }">
                  {{ humanDate(item.tanggal) }}
                </template>
                <template #cell(stok)="{ item }">
                  <strong>
                    {{
                        item.stok > 0 ? formatRupiah(item.stok) : item.stok
                    }}</strong>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="allowCreate() && row.item.jumlah > 0" variant="outline-primary"
                    @click="add(row.item)" size="sm">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    Disposal Rusak
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
        </b-card>
        <!-- two card -->
        <b-row>
          <!-- List barang di penerimaan -->
          <!-- <b-col sm="12" md="6">
            <b-card>
            </b-card>
          </b-col> -->
          <!-- Penempatan gudang -->
          <!-- <b-col sm="12" md="6">
            <b-card>
            </b-card>
          </b-col> -->
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card title="Daftar Disposal Rusak">
              <!-- <b-row>

                <b-col md="4" sm="12">
                  <b-button variant="outline-warning" @click="modal()">

                    <feather-icon icon="PrinterIcon"></feather-icon> Print / Cetak
                  </b-button>
                </b-col>
              </b-row> -->
              <b-modal v-model="modalPrint" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah"
                cancel-title="Batal" centered title="Pilih Tanggal yang akan di print">
                <b-form>
                  Dari :
                  <b-form-group>
                    <b-form-datepicker id="example-datepicker" v-model="tgl" class="mb-1" />
                  </b-form-group>
                  Ke :
                  <b-form-group>
                    <b-form-datepicker id="example-datepicker" v-model="tglend" class="mb-1" />
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="primary" @click="unduhbop">
                      Print / Cetak
                    </b-button>
                  </section>
                </template>
              </b-modal>
              <b-row>
                <b-col md="2" sm="4" class="my-1">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select id="perPageSelect" v-model="perPage2" size="sm" :options="pageOptions2"
                      class="w-50" />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                  <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm"
                    label-for="sortBySelect" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-select id="sortBySelect" v-model="sortBy2" :options="sortOptions2" class="w-75">
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select v-model="sortDesc2" size="sm" :disabled="!sortBy" class="w-25">
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                  <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                    label-for="filterInput2" class="mb-0">
                    <b-input-group size="sm">
              <b-form-input id="filterInput2" v-model="filter2" type="search" placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getData2()" />
              <b-input-group-append>
                <b-button :disabled="!filter2" @click="
                  filter = '';
                getData2();
                ">
                  Clear
                </b-button>
              </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-table small bordered striped hover responsive :per-page="perPage2" 
                    :items="items2" :fields="fields2" :sort-by.sync="sortBy2" :sort-desc.sync="sortDesc2"
                    :sort-direction="sortDirection2">
                    <template #cell(no)="row">
                      <strong class="text-center">
                        {{ row.index + 1 }}
                      </strong>
                    </template>
                    <template #cell(jumlah)="{ item }">
                      <strong>
                        {{
                            item.jumlah > 0 ? formatRupiah(item.jumlah) : item.jumlah
                        }}</strong>
                    </template>

                    <template #cell(actions)="row">
                      <!-- <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />Ubah
                </b-button> -->
                      <b-button v-b-tooltip.hover.right="'Hapus'" v-if="allowDelete()" size="sm"
                        @click="remove(row.item)" class="mr-1" variant="outline-danger">
                        <feather-icon icon="TrashIcon" />Hapus
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ul>
                          <li v-for="(value, key) in row.item" :key="key">
                            {{ key }}: {{ value }}
                          </li>
                        </ul>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination v-model="currentPage2" :total-rows="totalRows2" :per-page="perPage2" align="center"
                    size="sm" class="my-0" />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </main>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";

import "@core/scss/vue/libs/vue-flatpicker.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BForm,
  BOverlay,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea, BFormDatepicker
} from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    flatPickr,
    BCardActions,
    ValidationObserver,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BForm,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea, BFormDatepicker
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      namaBarang: null,
      satuan: null,
      optkonversi: [],
      nilai: 0,
      hasil: 0,
      konversi: 0,
      jumlah: 0,
      penyimpanan_id: "",
      keterangan: "",
      tanggal: this.getCurrentDate(),
      gudang: [],
      barang: [],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      id_barang: null,

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // {
        //   key: "id",
        //   label: "id",
        // },
        { key: "barang.nama", label: "barang", sortable: true },
        { key: "gudang.nama_gudang", label: "gudang", sortable: true },
        { key: "blok.blok", label: "Blok", sortable: true },
        { key: "palet.palet", label: "palet", sortable: true },
        { key: "rak.rak", label: "rak", sortable: true },
        { key: "laci.laci", label: "laci", sortable: true },
        { key: "jumlah", label: "jumlah", sortable: true },
        { key: "barang.satuan.satuan", label: "satuan", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      perPage2: 5,
      pageOptions2: [3, 5, 10],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      fields2: [
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl", sortable: true },
        { key: "penyimpanan.barang.nama", label: "Barang", sortable: true },
        { key: "penyimpanan.gudang.nama_gudang", label: "gudang", sortable: true },
        { key: "penyimpanan.blok.blok", label: "Blok", sortable: true },
        { key: "penyimpanan.palet.palet", label: "palet", sortable: true },
        { key: "penyimpanan.rak.rak", label: "rak", sortable: true },
        { key: "penyimpanan.laci.laci", label: "laci", sortable: true },
        { key: "jumlah", label: "jumlah", sortable: true },
        {
          key: "penyimpanan.barang.satuan.satuan",
          label: "Satuan",
          sortable: true,
        },
        { key: "keterangan", label: "keterangan", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items2: [],
      tgl: this.getCurrentDate(),
      tglend: this.getCurrentDate(),
      modalPrint: false,
      id_satuan: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
    this.getData2();
  },
  watch: {
    "id_barang"(barang_id) {
      if ( barang_id != null) {
        this.getKonversi(barang_id);
      }
    },
    "jumlah"(value2) {
      let hasil = value2 * this.id_satuan;
      this.nilai = hasil;
    },
    "id_satuan"(value) {
      let hasil = value * this.jumlah;
      this.nilai = hasil;
    },
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
        // this.items
      }
    },
    perPage2(value) {
      if (value) {
        this.getData2();
      }
    },
    // filter2(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData2();
    //   }
    // },
    currentPage2(val) {
      if (val) {
        this.getData2();
        // this.items
      }
    },
  },
  methods: {
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    modal() {
      this.modalPrint = true;
    },
    async unduhbop() {
      this.loading = true
      const response = await this.$store.dispatch("tokodisposalrusak/printAjuan", { id_gudang: this.myGudang.id, tgl_awal: this.tgl, tgl_akhir: this.tglend });
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    hem() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Disposal rusak ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("tokodisposalrusak/save", [item])
            .then(() => {
              this.displaySuccess({
                text: "Data Barang rusak sudah terhapus",
              });
              this.getData2();
              this.getData();
            })
            .catch((e) => {
              // this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.showModalTambah = false;
      this.showModalEdit = false;
      this.id = null;
      this.id_barang = null;
      this.namaBarang = null;
      this.satuan = null;
      this.optkonversi = null;
      this.jumlah = null;
      this.keterangan = null;
    },
    submit() {
      if (this.nilai == null || this.nilai == "") {
        this.pesanGagalSimpan();
        return false;
      }
      let payload = {
        penyimpanan_id: this.id,
        id_gudang: this.myGudang.id,
        jumlah: this.nilai,
        tanggal: this.tanggal,
        keterangan: this.keterangan,
      };

      if (this.id) {
        payload.penyimpanan_id = this.id;
        payload.id_gudang = this.myGudang.id
        payload.tanggal = this.tanggal;
        payload.jumlah = this.nilai;
        payload.keterangan = this.keterangan;
      }

      this.$store
        .dispatch("tokodisposalrusak/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.jumlah = null;
          this.showModalTambah = false;
          this.showModalEdit = false;
          this.getData2();
          this.getData();
          this.displaySuccess({
            text: "Data Barang rusak sudah tersimpan",
          });
          // this.pesanBerhasilSimpan();
        })
        .catch((e) => {
          this.displayError(e)
          return false
        });
    },
    add(item) {
      let { id, barang, satuan } = item;
      this.id = id;
      this.id_barang = barang.id;
      this.namaBarang = barang.nama;
      this.satuan = barang.satuan.satuan;
      this.showModalTambah = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getData() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        // blok_id: this.cariblok,
        // palet_id: this.caripalet,
        // rak_id: this.carirak,
        // laci_id: this.carilaci,
        // barang_id: this.caribarang,
        // kode_barang: this.carikodebarang,
        gudang_id: this.myGudang.id,
      };
      const penyimpananBarangs = await this.$store.dispatch(
        "tokopenyimpanan/getData",
        payload
      );
      // this.penyimpananBarangs = penyimpananBarangs;
      // this.totalRows = this.penyimpananBarangs.length;
      let items = JSON.parse(JSON.stringify(this.$store.state.tokopenyimpanan.datas));
      let items_total = this.$store.state.tokopenyimpanan.totals;
      this.items = items;
      this.totalRows = items_total;
      this.loading = false
    },
    async getData2() {
      this.loading = true
      const perPage2 = parseInt(this.perPage2);
      const currentPage2 = (parseInt(this.currentPage2) - 1) * perPage2;
      const payload = {
        search: this.filter2 != null ? this.filter2 : null,
        order: "desc",
        start: currentPage2,
        length: this.perPage2,
        // blok_id: this.cariblok,
        // palet_id: this.caripalet,
        // rak_id: this.carirak,
        // laci_id: this.carilaci,
        // barang_id: this.caribarang,
        // kode_barang: this.carikodebarang,
        gudang_id: this.myGudang.id,
      };
      const disposalrusak = await this.$store.dispatch(
        "tokodisposalrusak/getData",
        payload
      );
      // this.disposalrusak = disposalrusak;
      // this.totalRows = this.disposalrusak.length;
      let items = JSON.parse(JSON.stringify(this.$store.state.tokodisposalrusak.datas));
      let items_total = this.$store.state.tokodisposalrusak.totals;
      this.items2 = items;
      this.totalRows2 = items_total;
      this.loading = false
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
  },
};
</script>
